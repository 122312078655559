<script setup lang="ts">

import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})
const localePath = useLocalePath()
const handleError = () => clearError({ redirect: localePath('/') })
const errorText = computed(() => {
  if (props.error.statusCode == 404) {
    return 'Page not found'
  }
  if (props.error.statusCode == 403) {
    return 'Access denied'
  }
  if (props.error.statusMessage) {
    return props.error.statusMessage.indexOf(":") !== -1 ? props.error.statusMessage.substring(0, props.error.statusMessage.indexOf(":")) : props.error.message
  }
  return props.error.message;
})
</script>

<template>
  <div>
    <div class="w-full h-[calc(100vh)] bg-gradient-to-t from-secondary from-10% via-white/60 via-50% to-secondary/50 to-90%">
        <div class="container m-auto flex flex-center h-full flex-col">
          <ElementsTitle error :title="error.statusCode + ' ' + $t('errors.' + errorText)" class="mb-10"/>
          <ElementsButton @click="handleError">
            {{ $t('text.to index') }}
          </ElementsButton>
        </div>
      </div>
  </div>
</template>