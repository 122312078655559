import revive_payload_client_4sVQNw7RlN from "/var/www/new-barkar/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/new-barkar/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/new-barkar/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/var/www/new-barkar/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/new-barkar/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/new-barkar/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/new-barkar/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/new-barkar/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/new-barkar/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/var/www/new-barkar/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/new-barkar/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/new-barkar/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import acc_link_o2WdfvgeSz from "/var/www/new-barkar/plugins/acc-link.ts";
import date_LyjcPwWLrp from "/var/www/new-barkar/plugins/date.ts";
import events_client_4tGYDAYClX from "/var/www/new-barkar/plugins/events.client.ts";
import pageTransition_client_vnO6LwLOur from "/var/www/new-barkar/plugins/pageTransition.client.ts";
import pinia_48xmdi2HHl from "/var/www/new-barkar/plugins/pinia.ts";
import sentry_3AyO8nEfhE from "/var/www/new-barkar/plugins/sentry.ts";
import vue_final_modal_pML93k5qcp from "/var/www/new-barkar/plugins/vue-final-modal.ts";
import vue_google_maps_client_gpNQwFOD8U from "/var/www/new-barkar/plugins/vue-google-maps.client.ts";
import yupi18n_client_1FdO2pAmdl from "/var/www/new-barkar/plugins/yupi18n.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  acc_link_o2WdfvgeSz,
  date_LyjcPwWLrp,
  events_client_4tGYDAYClX,
  pageTransition_client_vnO6LwLOur,
  pinia_48xmdi2HHl,
  sentry_3AyO8nEfhE,
  vue_final_modal_pML93k5qcp,
  vue_google_maps_client_gpNQwFOD8U,
  yupi18n_client_1FdO2pAmdl
]