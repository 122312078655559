<script setup lang="ts">

const {locale} = useI18n();

const search = useSearchStore();
const core = useCoreStore();
const content = useContentStore();

useHead({
  titleTemplate: '%s - Barkar Apartments',
})

core.$patch({
  language: locale.value,
})

try {
  await useAsyncData('content', async () => await content.fetch().then(() => true))
  await useAsyncData('core', async () => await core.fetch().then(() => true))
  await useAsyncData('dates', async () => await search.fetch().then(() => true))
} catch (e) {
  console.log(e)
}


watch(locale, async function () {
  let data2 = await useApiFetch('get-content');
  content.$patch(data2)
})
const router = useRoute()
onMounted(() => {
  core.appLoaded = true;
  search.$subscribe((mutation, state) => {
    state.searchOptions = {
      'check_in': state.check_in,
      'check_out': state.check_out,
    }
  })
})

</script>

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#00bfff" class="h-2 w-full"/>
    <TheHeader v-if="$route.meta.layout === 'primary'"/>
    <div class="relative">
      <NuxtPage :keepalive="{include: ['3Accommodation', '2Place', '1Home']}"/>
    </div>
    <TheFooter/>
  </NuxtLayout>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: opacity 0.5s ease;
}

.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>