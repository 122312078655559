import {useSearchStore} from "~/stores/search";

export default defineNuxtPlugin(() => {
    return {
        provide: {
            getAccLink: (accommodation: any) => {
                const search = useSearchStore();
                let query = '';
                if (search.check_out) {
                    query += '?check_in=' + search.check_in + '&check_out=' + search.check_out
                }
                return '/accommodation/' + accommodation.translation.url + query
            }
        }
    }
})