export default defineNuxtPlugin(() => {
    addRouteMiddleware('global-middleware', (to, from) => {
            if (from.meta.layout === 'primary' && to.meta.layout === 'primary') {
                if (to.meta.pageTransition && typeof to.meta.pageTransition !== 'boolean') {
                    to.meta.pageTransition.name = parseInt(from.meta.key) < parseInt(to.meta.key) ? 'slide-left' : 'slide-right'
                }
                if (from.meta.pageTransition && typeof from.meta.pageTransition !== 'boolean') {
                    from.meta.pageTransition.name = parseInt(from.meta.key) > parseInt(to.meta.key) ? 'slide-left' : 'slide-right'
                }
            }
        },
        {global: true}
    )
})