<script setup lang="ts">

</script>

<template>
    <header class="w-full h-24 z-10 bg-secondary">
        <TheTopBar/>
    </header>
</template>

<style scoped>

</style>