<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

const emit = defineEmits<{
  (e: 'confirm'): void,
  (e: 'update:modelValue', modelValue: boolean): void,
}>()

const props = defineProps({
  contentClass: {
    type: String,
    default: 'flex flex-col mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2'
  },
  teleportTo: {
    type: [String, null, Boolean, Object],
    default: 'body',
  },
  title: {
    type: String,
  },
  stickyHeader: {
    type: Boolean,
  },
})

</script>

<template>
  <VueFinalModal
      class="flex justify-center items-center"
      :content-class="props.contentClass + ' relative'"
      :teleport-to="teleportTo"
      @update:model-value="val => emit('update:modelValue', val)"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <div :class="{'relative': title}">
      <div class="sticky flex items-center pl-5 bg-white left-0 top-0 w-full h-[60px] font-bold text-2xl" v-if="title">
        <span class="overflow-hidden w-full"> {{ title }}</span>
        <div class="absolute right-4 top-2 z-50" @click="emit('update:modelValue', false)">
          <Icon
              name="ep:close-bold"
              class="w-10 h-10 text-primary cursor-pointer"
          />
        </div>
      </div>
      <div class="absolute right-4 top-2 z-50" @click="emit('update:modelValue', false)" v-else>
        <Icon
            name="ep:close-bold"
            class="w-10 h-10 text-primary cursor-pointer"
        />
      </div>
      <div :class="{'pt-[50px]': title}">
        <slot/>
      </div>
    </div>
  </VueFinalModal>
</template>

<style scoped>

</style>