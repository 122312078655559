<script setup lang="ts">
const content = useContentStore();
const year = new Date().getYear() + 1900;

</script>

<template>
    <div class="bg-[#0b253c] text-[#E3E3E3] max-w-full overflow-hidden" v-if="content">
        <div class="container mx-auto flex flex-wrap md:flex-row flex-col py-[62px] px-10">
            <div class="md:w-[30%] pt-[62] md:pr-[66px] w-full flex flex-col flex-center mb-20">
                <NuxtLink :to="localePath('/')">
                    <img class="md:h-[50px] h-[40px] md:w-[185px] w-[148px] mb-[30px]" src="/images/logo-barkar-100.png" alt="logo">
                </NuxtLink>
                <div v-html="content.footerPromoText" class="text-[18px] text-[#E3E3E3]"/>
                <div v-html="content.address" class="text-[18px] text-[#E3E3E3] font-[275]"/>
            </div>
            <div class="md:w-[20%] mb-20">
                <div class="text-[30px] h-[79px] md:mb-[30px] mb-[12px] flex items-center md:justify-start justify-center capitalize ">{{ $t('text.contacts') }}</div>
                <div class="text-[20px] mb-[24px] font-light w-full text-center md:text-start">
                    <div class="p-[2.5px]">+38 063 7801222</div>
                    <div class="p-[2.5px]">+38 063 7801222</div>
                </div>
            </div>
            <div class="md:w-[20%] mb-20">
                <div class="text-[30px] h-[79px] md:mb-[30px] mb-[12px] flex justify-center md:justify-start items-center capitalize text-center w-full">
                  {{ $t('text.menu') }}
                </div>
                <ul class="text-[18px] flex flex-col items-center justify-center md:justify-start md:items-start">
                    <li v-for="link in content.footerMenu" class="py-[4px]">
                        <NuxtLink :to="localePath(link.url)">{{ link.title }}</NuxtLink>
                    </li>
                </ul>
            </div>
            <div class="md:w-[30%] w-full">
                <div class="text-[30px] h-[79px] md:mb-[30px] mb-[12px] flex items-center justify-center md:justify-start capitalize">{{ $t('text.join us') }}</div>
                <ul class=" flex mb-10 items-center justify-center md:justify-start">
                    <li class="mr-[15px]">
                        <a
                            href="https://www.facebook.com/BarkarApartments/"
                            aria-label="View us on FB"
                        >
                            <Icon name="ph:facebook-logo-thin" class="!w-10 !h-10 "/>
                        </a>
                    </li>
                    <li class="mr-[15px]">
                        <a href="https://www.facebook.com/BarkarApartments/"
                           aria-label="View us on Instagram">
                            <Icon name="ph:instagram-logo-thin" class="!w-10 !h-10 "/>
                        </a>
                    </li>
                </ul>
                <div class="text-[30px] h-[79px] mb-[10px] flex items-center justify-center md:justify-start items-center capitalize">
                    {{$t('text.safe payments') }}
                </div>
              <div class="flex w-full items-center justify-center md:justify-start">
                <Icon name="fa6-brands:cc-visa" class="!w-14 !h-14 lg:!w-20 lg:!h-20 mr-5 block"/>
                <Icon name="fa-brands:cc-mastercard" class="!w-14 !h-14 lg:!w-20 lg:!h-20 mr-5 block"/>
              </div>
            </div>
          <div class="text-[20px] w-full text-center md:text-start pt-10">
            <span class="block">office@barkar.apartments</span>
            <span>© Barkar Apartments <br>2013 - {{ year }}</span>
          </div>
        </div>
    </div>
</template>

<style scoped>

</style>