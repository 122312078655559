<script setup lang="ts">
import {useCoreStore} from "~/stores/core";
import {useContentStore} from "~/stores/content";

const {locale} = useI18n();
const router = useRouter();

const core = useCoreStore();
const content = useContentStore();

const showSettings = ref(false);

const languageModel = computed(() => {
  return core.languages ? core.languages.find((lang) => lang.url === locale.value) : {};
})

</script>

<template>
  <div class="w-full py-5 px-3 ">
    <div class="flex justify-between items-center container mx-auto ">
      <NuxtLinkLocale :to="'index'" class="z-20 h-[50px] flex items-center">
        <img src="/images/logo-barkar-100.png" class="md:h-[50px] h-[40px] md:w-[185px] w-[148px]" alt="Logo ">
      </NuxtLinkLocale>
      <ul class="lg:flex hidden items-center space-x-10 text-white overflow-hidden">
        <li
            v-for="item in content.links"
        >
          <NuxtLinkLocale class="font-bold hover:text-primary-hover" :to="item.url">{{ item.title }}</NuxtLinkLocale>
        </li>
      </ul>
      <div class="flex justify-between items-center">
        <ul class="lg:flex hidden items-center space-x-10 text-white overflow-hidden mr-5">
          <li
              v-for="item in content.buttons"
          >
            <NuxtLinkLocale :to="item.url">
              <ElementsButton
                  forever
                  class="hover:bg-secondary border-white h-10 !text-sm"
              >
                {{ item.title }}
              </ElementsButton>
            </NuxtLinkLocale>
          </li>
        </ul>
        <div
            v-if="languageModel"
            class="flex flex-center h-10 select-none cursor-pointer font-bold p-1 rounded-d bg-white mr-2"
            @click="showSettings = true">
          <div>{{ core.currency }}</div>
          /
          <div>{{ languageModel.url }}</div>
        </div>
        <TheMobileMenu/>
      </div>
    </div>
    <div class="flex">
      <PartsModal
          class="p-1"
          content-class="bg-white rounded-d md:p-10 p-5 max-w-full"
          v-model="showSettings"
      >
        <PickersSettings @close="showSettings = false"/>
      </PartsModal>
    </div>
  </div>
</template>

<style scoped>

</style>