<script setup lang="ts">
import {useCoreStore} from "~/stores/core";
import {useSearchStore} from "~/stores/search";

const {locale, setLocale} = useI18n();

const core = useCoreStore();
const search = useSearchStore();
const defaultModel = ref({
  language: locale.value,
  currency: core.currency,
})

const changed = computed(() => {
  return defaultModel.value.language !== locale.value || defaultModel.value.currency !== core.currency
})

const save = () => {
  core.$patch({
    currency: defaultModel.value.currency,
    language: defaultModel.value.language,
  })
  setLocale(defaultModel.value.language)
  emit('close')
}

const emit = defineEmits(['close'])
</script>

<template>
  <div class="flex flex-col">
    <div class="mb-5">
      <h2 class="mb-2">{{ $t('text.Select Language') }}</h2>
      <div class="flex flex-wrap">
        <div
            v-for="language in core.languages"
            @click="defaultModel.language = language.url"
            class="p-2 rounded-full"
            :class="{'bg-primary text-white': defaultModel.language === language.url, 'cursor-pointer': defaultModel.language !== language.url}"
        >
          {{ language.name }}
        </div>
      </div>
    </div>
    <div>
      <h2 class="mb-2">{{$t('text.Select Currency')}}</h2>
      <div class="flex mb-5">
        <div
            v-for="currency in core.currencies"
            class="p-2 rounded-full"
            @click="defaultModel.currency = currency.iso"
            :class="{'bg-primary text-white': defaultModel.currency === currency.iso, 'cursor-pointer': defaultModel.currency !== currency.iso}"
        >
          {{ currency.iso }}
        </div>
      </div>
    </div>
    <ElementsButton
        @click="save"
        v-if="changed"
        class="self-end"
    >
      <button>{{ $t('text.save') }}</button>
    </ElementsButton>
  </div>
</template>

<style scoped>

</style>