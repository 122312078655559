
import {useApiFetch} from "~/composables/useApiFetch";

export const useContentStore = defineStore('content', () => {
    const footerPromoText = ref('')
    const address = ref('')
    const footerMenu = ref('')

    const links = computed(() => {
        if (footerMenu.value)
            return footerMenu.value.filter(obj => obj.type !== 'btn')
    })

    const buttons = computed(() => {
        if (footerMenu.value)
            return footerMenu.value.filter(obj => obj.type === 'btn')
    })

     const fetch = async () => {
         return await useApiFetch('get-content').then((res) => {
             footerPromoText.value = res.footerPromoText;
             address.value = res.address;
             footerMenu.value = res.footerMenu;
         })
     }

    return {
        footerPromoText,
        footerMenu,
        address,
        links,
        buttons,
        fetch
    };
})