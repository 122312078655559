import { default as _91_46_46_46all_930VMdD7Hx9KMeta } from "/var/www/new-barkar/pages/[...all].vue?macro=true";
import { default as _91accommodation_939nlqQKuQYdMeta } from "/var/www/new-barkar/pages/accommodation/[accommodation].vue?macro=true";
import { default as indexAOhfcpvoZXMeta } from "/var/www/new-barkar/pages/index.vue?macro=true";
import { default as _91id_934GzfvFZWq9Meta } from "/var/www/new-barkar/pages/reservation/[id].vue?macro=true";
import { default as _91hash_93akvZqmkmYQMeta } from "/var/www/new-barkar/pages/reservation/confirmation/[hash].vue?macro=true";
import { default as status_45_91state_93DjwwUhRbLXMeta } from "/var/www/new-barkar/pages/reservation/status-[state].vue?macro=true";
import { default as _91place_93smavqzItujMeta } from "/var/www/new-barkar/pages/search-[type]/[place].vue?macro=true";
import { default as _91page_93gUGyyTjU5pMeta } from "/var/www/new-barkar/pages/site/[page].vue?macro=true";
import { default as contributionJwMktJv0lkMeta } from "/var/www/new-barkar/pages/site/contribution.vue?macro=true";
import { default as component_45stub5fqwU0XWKbMeta } from "/var/www/new-barkar/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub5fqwU0XWKb } from "/var/www/new-barkar/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46all_930VMdD7Hx9KMeta?.name ?? "all___ru",
    path: _91_46_46_46all_930VMdD7Hx9KMeta?.path ?? "/ru/:all(.*)*",
    meta: _91_46_46_46all_930VMdD7Hx9KMeta || {},
    alias: _91_46_46_46all_930VMdD7Hx9KMeta?.alias || [],
    redirect: _91_46_46_46all_930VMdD7Hx9KMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_930VMdD7Hx9KMeta?.name ?? "all___uk",
    path: _91_46_46_46all_930VMdD7Hx9KMeta?.path ?? "/uk/:all(.*)*",
    meta: _91_46_46_46all_930VMdD7Hx9KMeta || {},
    alias: _91_46_46_46all_930VMdD7Hx9KMeta?.alias || [],
    redirect: _91_46_46_46all_930VMdD7Hx9KMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_930VMdD7Hx9KMeta?.name ?? "all___tr",
    path: _91_46_46_46all_930VMdD7Hx9KMeta?.path ?? "/tr/:all(.*)*",
    meta: _91_46_46_46all_930VMdD7Hx9KMeta || {},
    alias: _91_46_46_46all_930VMdD7Hx9KMeta?.alias || [],
    redirect: _91_46_46_46all_930VMdD7Hx9KMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_930VMdD7Hx9KMeta?.name ?? "all___en",
    path: _91_46_46_46all_930VMdD7Hx9KMeta?.path ?? "/en/:all(.*)*",
    meta: _91_46_46_46all_930VMdD7Hx9KMeta || {},
    alias: _91_46_46_46all_930VMdD7Hx9KMeta?.alias || [],
    redirect: _91_46_46_46all_930VMdD7Hx9KMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91accommodation_939nlqQKuQYdMeta?.name ?? "accommodation-accommodation___ru",
    path: _91accommodation_939nlqQKuQYdMeta?.path ?? "/ru/accommodation/:accommodation()",
    meta: _91accommodation_939nlqQKuQYdMeta || {},
    alias: _91accommodation_939nlqQKuQYdMeta?.alias || [],
    redirect: _91accommodation_939nlqQKuQYdMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/accommodation/[accommodation].vue").then(m => m.default || m)
  },
  {
    name: _91accommodation_939nlqQKuQYdMeta?.name ?? "accommodation-accommodation___uk",
    path: _91accommodation_939nlqQKuQYdMeta?.path ?? "/uk/accommodation/:accommodation()",
    meta: _91accommodation_939nlqQKuQYdMeta || {},
    alias: _91accommodation_939nlqQKuQYdMeta?.alias || [],
    redirect: _91accommodation_939nlqQKuQYdMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/accommodation/[accommodation].vue").then(m => m.default || m)
  },
  {
    name: _91accommodation_939nlqQKuQYdMeta?.name ?? "accommodation-accommodation___tr",
    path: _91accommodation_939nlqQKuQYdMeta?.path ?? "/tr/accommodation/:accommodation()",
    meta: _91accommodation_939nlqQKuQYdMeta || {},
    alias: _91accommodation_939nlqQKuQYdMeta?.alias || [],
    redirect: _91accommodation_939nlqQKuQYdMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/accommodation/[accommodation].vue").then(m => m.default || m)
  },
  {
    name: _91accommodation_939nlqQKuQYdMeta?.name ?? "accommodation-accommodation___en",
    path: _91accommodation_939nlqQKuQYdMeta?.path ?? "/en/accommodation/:accommodation()",
    meta: _91accommodation_939nlqQKuQYdMeta || {},
    alias: _91accommodation_939nlqQKuQYdMeta?.alias || [],
    redirect: _91accommodation_939nlqQKuQYdMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/accommodation/[accommodation].vue").then(m => m.default || m)
  },
  {
    name: indexAOhfcpvoZXMeta?.name ?? "index___ru",
    path: indexAOhfcpvoZXMeta?.path ?? "/ru",
    meta: indexAOhfcpvoZXMeta || {},
    alias: indexAOhfcpvoZXMeta?.alias || [],
    redirect: indexAOhfcpvoZXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAOhfcpvoZXMeta?.name ?? "index___uk",
    path: indexAOhfcpvoZXMeta?.path ?? "/uk",
    meta: indexAOhfcpvoZXMeta || {},
    alias: indexAOhfcpvoZXMeta?.alias || [],
    redirect: indexAOhfcpvoZXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAOhfcpvoZXMeta?.name ?? "index___tr",
    path: indexAOhfcpvoZXMeta?.path ?? "/tr",
    meta: indexAOhfcpvoZXMeta || {},
    alias: indexAOhfcpvoZXMeta?.alias || [],
    redirect: indexAOhfcpvoZXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAOhfcpvoZXMeta?.name ?? "index___en",
    path: indexAOhfcpvoZXMeta?.path ?? "/en",
    meta: indexAOhfcpvoZXMeta || {},
    alias: indexAOhfcpvoZXMeta?.alias || [],
    redirect: indexAOhfcpvoZXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934GzfvFZWq9Meta?.name ?? "reservation-id___ru",
    path: _91id_934GzfvFZWq9Meta?.path ?? "/ru/reservation/:id()",
    meta: _91id_934GzfvFZWq9Meta || {},
    alias: _91id_934GzfvFZWq9Meta?.alias || [],
    redirect: _91id_934GzfvFZWq9Meta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934GzfvFZWq9Meta?.name ?? "reservation-id___uk",
    path: _91id_934GzfvFZWq9Meta?.path ?? "/uk/reservation/:id()",
    meta: _91id_934GzfvFZWq9Meta || {},
    alias: _91id_934GzfvFZWq9Meta?.alias || [],
    redirect: _91id_934GzfvFZWq9Meta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934GzfvFZWq9Meta?.name ?? "reservation-id___tr",
    path: _91id_934GzfvFZWq9Meta?.path ?? "/tr/reservation/:id()",
    meta: _91id_934GzfvFZWq9Meta || {},
    alias: _91id_934GzfvFZWq9Meta?.alias || [],
    redirect: _91id_934GzfvFZWq9Meta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934GzfvFZWq9Meta?.name ?? "reservation-id___en",
    path: _91id_934GzfvFZWq9Meta?.path ?? "/en/reservation/:id()",
    meta: _91id_934GzfvFZWq9Meta || {},
    alias: _91id_934GzfvFZWq9Meta?.alias || [],
    redirect: _91id_934GzfvFZWq9Meta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93akvZqmkmYQMeta?.name ?? "reservation-confirmation-hash___ru",
    path: _91hash_93akvZqmkmYQMeta?.path ?? "/ru/reservation/confirmation/:hash()",
    meta: _91hash_93akvZqmkmYQMeta || {},
    alias: _91hash_93akvZqmkmYQMeta?.alias || [],
    redirect: _91hash_93akvZqmkmYQMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/confirmation/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93akvZqmkmYQMeta?.name ?? "reservation-confirmation-hash___uk",
    path: _91hash_93akvZqmkmYQMeta?.path ?? "/uk/reservation/confirmation/:hash()",
    meta: _91hash_93akvZqmkmYQMeta || {},
    alias: _91hash_93akvZqmkmYQMeta?.alias || [],
    redirect: _91hash_93akvZqmkmYQMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/confirmation/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93akvZqmkmYQMeta?.name ?? "reservation-confirmation-hash___tr",
    path: _91hash_93akvZqmkmYQMeta?.path ?? "/tr/reservation/confirmation/:hash()",
    meta: _91hash_93akvZqmkmYQMeta || {},
    alias: _91hash_93akvZqmkmYQMeta?.alias || [],
    redirect: _91hash_93akvZqmkmYQMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/confirmation/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93akvZqmkmYQMeta?.name ?? "reservation-confirmation-hash___en",
    path: _91hash_93akvZqmkmYQMeta?.path ?? "/en/reservation/confirmation/:hash()",
    meta: _91hash_93akvZqmkmYQMeta || {},
    alias: _91hash_93akvZqmkmYQMeta?.alias || [],
    redirect: _91hash_93akvZqmkmYQMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/confirmation/[hash].vue").then(m => m.default || m)
  },
  {
    name: status_45_91state_93DjwwUhRbLXMeta?.name ?? "reservation-status-state___ru",
    path: status_45_91state_93DjwwUhRbLXMeta?.path ?? "/ru/reservation/status-:state()",
    meta: status_45_91state_93DjwwUhRbLXMeta || {},
    alias: status_45_91state_93DjwwUhRbLXMeta?.alias || [],
    redirect: status_45_91state_93DjwwUhRbLXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/status-[state].vue").then(m => m.default || m)
  },
  {
    name: status_45_91state_93DjwwUhRbLXMeta?.name ?? "reservation-status-state___uk",
    path: status_45_91state_93DjwwUhRbLXMeta?.path ?? "/uk/reservation/status-:state()",
    meta: status_45_91state_93DjwwUhRbLXMeta || {},
    alias: status_45_91state_93DjwwUhRbLXMeta?.alias || [],
    redirect: status_45_91state_93DjwwUhRbLXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/status-[state].vue").then(m => m.default || m)
  },
  {
    name: status_45_91state_93DjwwUhRbLXMeta?.name ?? "reservation-status-state___tr",
    path: status_45_91state_93DjwwUhRbLXMeta?.path ?? "/tr/reservation/status-:state()",
    meta: status_45_91state_93DjwwUhRbLXMeta || {},
    alias: status_45_91state_93DjwwUhRbLXMeta?.alias || [],
    redirect: status_45_91state_93DjwwUhRbLXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/status-[state].vue").then(m => m.default || m)
  },
  {
    name: status_45_91state_93DjwwUhRbLXMeta?.name ?? "reservation-status-state___en",
    path: status_45_91state_93DjwwUhRbLXMeta?.path ?? "/en/reservation/status-:state()",
    meta: status_45_91state_93DjwwUhRbLXMeta || {},
    alias: status_45_91state_93DjwwUhRbLXMeta?.alias || [],
    redirect: status_45_91state_93DjwwUhRbLXMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/reservation/status-[state].vue").then(m => m.default || m)
  },
  {
    name: _91place_93smavqzItujMeta?.name ?? "search-type-place___ru",
    path: _91place_93smavqzItujMeta?.path ?? "/ru/search-:type()/:place()",
    meta: _91place_93smavqzItujMeta || {},
    alias: _91place_93smavqzItujMeta?.alias || [],
    redirect: _91place_93smavqzItujMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/search-[type]/[place].vue").then(m => m.default || m)
  },
  {
    name: _91place_93smavqzItujMeta?.name ?? "search-type-place___uk",
    path: _91place_93smavqzItujMeta?.path ?? "/uk/search-:type()/:place()",
    meta: _91place_93smavqzItujMeta || {},
    alias: _91place_93smavqzItujMeta?.alias || [],
    redirect: _91place_93smavqzItujMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/search-[type]/[place].vue").then(m => m.default || m)
  },
  {
    name: _91place_93smavqzItujMeta?.name ?? "search-type-place___tr",
    path: _91place_93smavqzItujMeta?.path ?? "/tr/search-:type()/:place()",
    meta: _91place_93smavqzItujMeta || {},
    alias: _91place_93smavqzItujMeta?.alias || [],
    redirect: _91place_93smavqzItujMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/search-[type]/[place].vue").then(m => m.default || m)
  },
  {
    name: _91place_93smavqzItujMeta?.name ?? "search-type-place___en",
    path: _91place_93smavqzItujMeta?.path ?? "/en/search-:type()/:place()",
    meta: _91place_93smavqzItujMeta || {},
    alias: _91place_93smavqzItujMeta?.alias || [],
    redirect: _91place_93smavqzItujMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/search-[type]/[place].vue").then(m => m.default || m)
  },
  {
    name: _91page_93gUGyyTjU5pMeta?.name ?? "site-page___ru",
    path: _91page_93gUGyyTjU5pMeta?.path ?? "/ru/site/:page()",
    meta: _91page_93gUGyyTjU5pMeta || {},
    alias: _91page_93gUGyyTjU5pMeta?.alias || [],
    redirect: _91page_93gUGyyTjU5pMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/[page].vue").then(m => m.default || m)
  },
  {
    name: _91page_93gUGyyTjU5pMeta?.name ?? "site-page___uk",
    path: _91page_93gUGyyTjU5pMeta?.path ?? "/uk/site/:page()",
    meta: _91page_93gUGyyTjU5pMeta || {},
    alias: _91page_93gUGyyTjU5pMeta?.alias || [],
    redirect: _91page_93gUGyyTjU5pMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/[page].vue").then(m => m.default || m)
  },
  {
    name: _91page_93gUGyyTjU5pMeta?.name ?? "site-page___tr",
    path: _91page_93gUGyyTjU5pMeta?.path ?? "/tr/site/:page()",
    meta: _91page_93gUGyyTjU5pMeta || {},
    alias: _91page_93gUGyyTjU5pMeta?.alias || [],
    redirect: _91page_93gUGyyTjU5pMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/[page].vue").then(m => m.default || m)
  },
  {
    name: _91page_93gUGyyTjU5pMeta?.name ?? "site-page___en",
    path: _91page_93gUGyyTjU5pMeta?.path ?? "/en/site/:page()",
    meta: _91page_93gUGyyTjU5pMeta || {},
    alias: _91page_93gUGyyTjU5pMeta?.alias || [],
    redirect: _91page_93gUGyyTjU5pMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/[page].vue").then(m => m.default || m)
  },
  {
    name: contributionJwMktJv0lkMeta?.name ?? "site-contribution___ru",
    path: contributionJwMktJv0lkMeta?.path ?? "/ru/site/contribution",
    meta: contributionJwMktJv0lkMeta || {},
    alias: contributionJwMktJv0lkMeta?.alias || [],
    redirect: contributionJwMktJv0lkMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/contribution.vue").then(m => m.default || m)
  },
  {
    name: contributionJwMktJv0lkMeta?.name ?? "site-contribution___uk",
    path: contributionJwMktJv0lkMeta?.path ?? "/uk/site/contribution",
    meta: contributionJwMktJv0lkMeta || {},
    alias: contributionJwMktJv0lkMeta?.alias || [],
    redirect: contributionJwMktJv0lkMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/contribution.vue").then(m => m.default || m)
  },
  {
    name: contributionJwMktJv0lkMeta?.name ?? "site-contribution___tr",
    path: contributionJwMktJv0lkMeta?.path ?? "/tr/site/contribution",
    meta: contributionJwMktJv0lkMeta || {},
    alias: contributionJwMktJv0lkMeta?.alias || [],
    redirect: contributionJwMktJv0lkMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/contribution.vue").then(m => m.default || m)
  },
  {
    name: contributionJwMktJv0lkMeta?.name ?? "site-contribution___en",
    path: contributionJwMktJv0lkMeta?.path ?? "/en/site/contribution",
    meta: contributionJwMktJv0lkMeta || {},
    alias: contributionJwMktJv0lkMeta?.alias || [],
    redirect: contributionJwMktJv0lkMeta?.redirect,
    component: () => import("/var/www/new-barkar/pages/site/contribution.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5fqwU0XWKbMeta?.name ?? undefined,
    path: component_45stub5fqwU0XWKbMeta?.path ?? "/qr/entrance",
    meta: component_45stub5fqwU0XWKbMeta || {},
    alias: component_45stub5fqwU0XWKbMeta?.alias || [],
    redirect: component_45stub5fqwU0XWKbMeta?.redirect,
    component: component_45stub5fqwU0XWKb
  }
]