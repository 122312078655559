<script setup lang="ts">
const props = defineProps({
  disabled: Boolean,
  loading: Boolean,
  oneTime: Boolean,
  action: Boolean,
  forever: Boolean,
})

defineEmits(['click'])

const state = reactive({
  isLoading: false,
  isLoaded: false,
})

watch(() => props.loading, (val) => {
  if (val) {
    state.isLoading = true;
  } else {
    if (props.forever) return;
    state.isLoading = false;
    state.isLoaded = true;
    if (!props.oneTime) {
      setTimeout(() => {
        state.isLoaded = false;
      }, 1000)
    }
  }
})

</script>

<template>
  <button
      :disabled="disabled || state.isLoading || state.isLoaded"
      type="submit"
      @click="$emit('click')"
      class="rounded-d uppercase select-none md:p-[15px] overflow-hidden p-[10px] md:text-[18px] text-[16px] border-2 font-bold relative w-full md:w-auto flex flex-center transition duration-200 whitespace-nowrap active:scale-95"
      :class="{
                'opacity-30' : state.isLoading,
                '!bg-success !border-success !text-white' : state.isLoaded,
                'border-secondary hover:bg-secondary hover:text-white' : action
            }"
  >
    <div class="opacity-0">
      <slot/>
    </div>
    <div class="!absolute inset-center whitespace-nowrap">
      <Transition name="v-btn-label" mode="out-in" tag="div" >
        <div v-if="!state.isLoading && !state.isLoaded" class="flex items-center">
          <slot/>
        </div>
        <div v-else-if="state.isLoading">
          <slot name="loader">
            <Icon name="mingcute:loading-fill" class="w-10 h-10 animate-spin"/>
          </slot>
        </div>
        <div v-else>
          <slot name="success">
            <Icon name="ep:success-filled" class="w-10 h-10"/>
          </slot>
        </div>
      </Transition>
    </div>
  </button>
</template>

<style scoped>
.v-btn-label-enter-active {
  animation: slide-in-down 260ms cubic-bezier(.0, .0, .2, 1);
}

.v-btn-label-leave-active {
  animation: slide-out-down 260ms cubic-bezier(.4, .0, 1, 1);
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  50% {
    opacity: .8;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    opacity: .2;
  }

  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}
</style>