import format from 'date-fns/format'

import ru from 'date-fns/locale/ru'
import uk from 'date-fns/locale/uk'
import tr from 'date-fns/locale/tr'

const datefns: any = { format }

const locales = {uk, ru, tr};
export default defineNuxtPlugin(() => {
    const { $i18n } = useNuxtApp()
    return {
        provide: {
            dateFns: datefns,
            format: (date: string, formate = 'dd MMMM') => datefns.format(Date.parse(date), formate, {'locale': locales[$i18n.locale._value]})
        }
    }
})