<script setup lang="ts">
import {useContentStore} from "~/stores/content";
import {useModalsStore} from "~/stores/modals";
import Modal from "~/components/parts/Modal.vue";

const router = useRouter();

const modals = useModalsStore();

const content = useContentStore();

</script>

<template>
  <div>
    <div
        id="nav-icon1"
        class="relative w-10 h-10 select-none z-20 lg:hidden cursor-pointer"
        :class="{'open ': modals.mainMobileMenu}"
        @click="modals.mainMobileMenu = !modals.mainMobileMenu"
    >
      <span v-for="i in 3" :class="modals.mainMobileMenu ? 'bg-primary' : 'bg-white'"></span>
    </div>
    <Transition name="fade-fast">
      <div class="absolute top-0 left-0 w-full h-full z-10 flex flex-center flex-col backdrop-blur-md bg-secondary/80" v-show="modals.mainMobileMenu">
        <ul class="flex flex-center text-secondary flex-col mb-20">
          <li
              v-for="item in content.links"
              class="flex flex-center text-3xl font-light text-white mb-3"
              @click="modals.mainMobileMenu = false"
          >
            <NuxtLinkLocale :to="item.url" class="py-2">
              {{ item.title }}
            </NuxtLinkLocale>
          </li>
        </ul>
        <ul class="flex flex-center text-secondary flex-col">
          <li
              v-for="item in content.buttons"
              class="flex flex-center text-2xl font-light text-white"
              @click="modals.mainMobileMenu = false"
          >
            <NuxtLinkLocale :to="item.url">
              <ElementsButton
                  forever
                  class="hover:bg-secondary border-white h-14 !text-xl px-10"
              >
                {{ item.title }}
              </ElementsButton>
            </NuxtLinkLocale>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style scoped>


#nav-icon1 span {
  display: block;
  position: absolute;
  height: 14%;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 15%;
}

#nav-icon1 span:nth-child(2) {
  top: 45%;
}

#nav-icon1 span:nth-child(3) {
  top: 75%;
}

#nav-icon1.open span:nth-child(1) {
  top: 50%;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -110%;
}

#nav-icon1.open span:nth-child(3) {
  top: 50%;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
</style>