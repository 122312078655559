export default defineNuxtPlugin(() => {
    const gtm = useGtm()

    return {
        provide: {
            events: {
                generateLead: (params: any) => {
                    gtm.trackEvent({event: 'generate_lead', ...params})
                }
            }
        }
    }
})