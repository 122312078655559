export function handleApiError(response: { status: number; _data: { message: string | undefined } }) {
    const router = useRouter()

    if (response.status == 404) {
        return showError({message: 'Page not found', statusCode: 404})
    }
    if (response.status > 400) {
        return showError({message: response._data ? response._data.message : '', statusCode: response.status})
    }
    if (response.status == 301) {
        return router.replace('/')
    }
}