import {defineStore, skipHydrate} from 'pinia'

export const useSearchStore = defineStore('search', () => {
    const route = useRoute();

    const check_in = ref('')
    const check_out = ref('')
    const errors = ref([])
    const expires = new Date();
    expires.setTime(expires.getTime() + (3 * 24 * 60 * 60 * 1000));

    const searchOptions = useCookie(
        'searchOptions',
        {
            expires: expires,
        }
    )

    const fetch = (async () => {
        const dates = await useApiFetch(
            'get-dates',
            {
                body: {
                    query: route.query,
                    cookie: searchOptions.value
                }
            }
        )

        check_in.value = dates.start;
        check_out.value = dates.end;
        errors.value = dates.errors;
    });

    return {
        check_in,
        check_out,
        fetch,
        searchOptions
    };
})