import {defineStore} from 'pinia'
import {useApiFetch} from "~/composables/useApiFetch";

export const useCoreStore = defineStore('core', () => {
    const {locale} = useI18n();

    const currencies = ref([])
    const languages = ref([])
    const expires = new Date();
    const appLoaded = ref(false);

    expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));

    const currency = useCookie('currency', {
            default: () => ('UAH'),
            expires: expires,
        }
    )

    const language = useCookie('language', {
            default: () => (locale.value),
            expires: expires
        }
    )

    const fetch = async () => {
        const core = await useApiFetch('get-core')

        currencies.value = core.currencies;
        languages.value = core.languages;
    }

    return {
        currencies,
        languages,
        currency,
        language,
        appLoaded,
        fetch
    };
})